export const formatLanguageCode = (lang: string) => {
	switch (lang) {
		case 'it':
		case 'it-IT':
			return 'it-IT'
		case 'es':
		case 'es-ES':
			return 'es-ES'
		case 'ca':
		case 'ca-ES':
			return 'ca-ES'
		case 'fr':
		case 'fr-FR':
			return 'fr-FR'
		default:
			return 'en-US'
	}
}
