import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { formatLanguageCode } from './utils/languageUtils'
import appConfig from './config'

const PROJECT_NAME = appConfig.translations.project
const BASE_URL = appConfig.translations.url

const options = {
	returnNull: false,
	returnDetails: false,
	returnObjects: false,
	supportedLngs: [
		'en-US',
		'fr',
		'fr-FR',
		'es',
		'es-ES',
		'ca',
		'ca-ES',
		'it',
		'it-IT',
	],
	detection: {
		order: ['localStorage', 'cookie', 'navigator'],
		lookupCookie: 'i18next',
		lookupLocalStorage: 'i18nextLng',
		caches: ['localStorage', 'cookie'],
	},
	ns: ['translation'], // Make sure namespaces are defined
	defaultNS: 'translation',
	fallbackLng: 'en-US',
	debug: false,
	interpolation: {
		escapeValue: false, // not needed for react as it escapes by default
	},
	backend: {
		crossDomain: true,
		loadPath: (lngs: readonly string[], namespaces: readonly string[]) => {
			const formattedLng = formatLanguageCode(lngs[0] as string)
			const ns = namespaces[0] as string

			return appConfig.environment === 'localhost'
				? `/locales/{{lng}}/{{ns}}.json?t=${Date.now()}`
				: `${BASE_URL}/${PROJECT_NAME}/${formattedLng}/${ns}.json`
		},
	},
	saveMissing: true,
	missingKeyHandler: (
		lng: readonly string[],
		ns: string,
		key: string,
		fallbackValue: string,
	) => {
		console.warn(`[connect] key "${key}" is missing`, {
			namespace: ns,
			language: lng,
			fallbackValue,
		})
	},
}

i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-xhr-backend
	.use(HttpApi)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init(options)

export default i18n

export const LABEL_INTERPOLATION_MARKER = '%%1'
